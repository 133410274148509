import React, { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import "./scss/style.scss";
import store from "./redux-store/store";
import PopupEmployeeMapLocation from "./views/pages/employee/PopupEmployeeMapLocation";
import PopupStoreInfo from "./views/pages/store/PopupStoreInfo";
import PopupBoardDetail from "./views/popup/PopupBoardDetail";

const loading = (
  <div className={"pt-3 text-center"}>
    <div className={"sk-spinner sk-spinner-pulse"}/>
  </div>
);

const Login = React.lazy(() => import('./views/pages/login/Login'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Store = React.lazy(() => import('./views/pages/store/StoreList'))

import "./tailwind.css";
import StockIn from "./views/pages/stock/sub/StockIn";
import StockOut from "./views/pages/stock/sub/StockOut";

function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path={"/"} name={"Check"} element={<Login/>}/>
            <Route exact path="/login" name="Login Page" element={<Login/>}/>
            <Route exact path="/register" name="Register Page" element={<Login/>}/>
            <Route exact path="/404" name="Page 404" element={<Login/>}/>
            <Route exact path="/500" name="Page 500" element={<Login/>}/>
            <Route exact path="*" name="Dashboard" element={<DefaultLayout/>}/>
            <Route exact path="/popup/employee/location" element={<PopupEmployeeMapLocation/>} />
            <Route exact path="/popup/store/info" element={<PopupStoreInfo />} />
            <Route exact path="/popup/board/detail" element={<PopupBoardDetail/>} />
            <Route exact path={"/popup/stock/stock-in"} element={<StockIn />} />
            <Route exact path={"/popup/stock/stock-out"} element={<StockOut />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </Provider>
  );
}

export default App;