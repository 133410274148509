import {connect} from "react-redux";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Loading} from "../../../components/common";

const PopupEmployeeMapLocation = () => {
    let map;

    const [params] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const queryList = [...params];
    const lat = queryList[0][1];
    const lng = queryList[1][1];
    console.log("lat lng :: ", lat, lng);

    useEffect(() => {
        mapScript();
    }, []);

    const mapScript = () => {
        const container = document.getElementById('employeeMap');
        const options = {
            center: new kakao.maps.LatLng("33.450701", "126.570667"),
            level: 3
        };
        map = new kakao.maps.Map(container, options);

        let markerPosition = new kakao.maps.LatLng(lat, lng);
        let marker = new kakao.maps.Marker({
            map: map,
            position: markerPosition
        })
        map.panTo(markerPosition);
    }

    return (
        <>
            <div style={{padding: 16}}>
                {loading ? <Loading/> : null}
                <div style={{borderRadius: 5, padding: 10, marginBottom: 10, background: "#13319b"}}>

                </div>
                <div id={"employeeMap"} style={{width: "100%", height: 600}}/>
            </div>
        </>
    )
}

export default connect(null, null)(PopupEmployeeMapLocation);