const INIT_FOOTER_MENU = "@@INIT_FOOTER_MENU";
const ADD_FOOTER_MENU = '@@ADD_FOOTER_MENU';
const DEL_FOOTER_MENU = '@@DEL_FOOTER_MENU';

const initialState = [];

function initFooterMenu() {
	return {
		type: INIT_FOOTER_MENU
	}
}

function applyInitFooterMenu(state) {
	return {
		...state,
		menuList: []
	};
}

function addFooterMenu(menu) {
	return {
		type: ADD_FOOTER_MENU,
		menu
	}
}

function applyAddFooterMenu(state, action) {
	const { menuList } = state;
	const { menu } = action;

	let prod = menuList;
	if (prod === undefined) prod = [];
	// 중복 키가 있는지 확인
	const idx = prod.findIndex((item, idx) => { return item.id === menu.id });
	console.log("### idx :: ", idx);
	if (idx === -1) prod = prod.concat(menu);

	return {
		...state,
		menuList: prod
	}
}

function delFooterMenu(menu) {
	return {
		type: DEL_FOOTER_MENU,
		menu
	}
}

function applyDelFooterMenu(state, action) {
	const { menuList } = state;
	const { menu } = action;

	let prod = menuList;
	if (prod === undefined) prod = [];
	const idx = prod.findIndex((item, idx) => { return item === menu });
	console.log("idx :: ", idx);
	if (idx > -1) {
		prod.splice(idx, 1);
	}

	return {
		...state,
		menuList: prod
	}
}

function reducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
		case INIT_FOOTER_MENU:
			return applyInitFooterMenu(state);
		case ADD_FOOTER_MENU:
			return applyAddFooterMenu(state, action);
		case DEL_FOOTER_MENU:
			return applyDelFooterMenu(state, action);
	}
}

const actionCreator = {
	initFooterMenu,
	addFooterMenu,
	delFooterMenu
};

export { actionCreator as footerActions };

export default reducer;
