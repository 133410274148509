import {useEffect, useState} from "react";
import * as api from "../../../../api/api";

const StorageRenderer = (props) => {
    const [value, setValue] = useState(props.value);
    const [data, setData] = useState([]);

    useEffect(() => {
        getStorageList();
    }, [])

    const getStorageList = () => {
        const params = {
            companyCode: 'VAN', useYn: 'Y'
        }
        api.getStorageList(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                const optionArr = [];
                data.map(item => {
                    const option = {
                        subCode: item.storageCd,
                        codeNm: item.storageNm
                    }
                    optionArr.push(option);
                })
                setData(optionArr);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
    }

    const handleChange = (e) => {
        props.onEventChange(props.rowIndex, e.target.value);
    }

    return (
        <div>
            <select value={value}
                    className={"border h-6"}
                    onChange={handleChange}>
                {data.map((option, index) => (
                    <option key={index}
                            value={option.subCode}>
                        {option.codeNm}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default StorageRenderer;