import {connect, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Loading} from "../../../components/common";
import * as api from "../../../api/api";
import {
    CButton,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormSelect, CFormTextarea,
    CInputGroup,
    CInputGroupText,
    CRow
} from "@coreui/react";
import {businessOptions} from "../../../common/businessOptions";
import {eventOptions} from "../../../common/eventOptions";
import {AgGridReact} from "ag-grid-react";
import {boardPostTypeFormatter, boardStatusFormatter} from "../../../utils/formatter";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {SearchOutlined} from "@ant-design/icons";
import {getStoreHaveProductList} from "../../../api/api";

const PopupStoreInfo = () => {
    const [params] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [selectEmailHost, setSelectEmailHost] = useState("");
    const [emailHost, setEmailHost] = useState("");
    const [data, setData] = useState({});
    const [storeProductList, setStoreProductList] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const [formData, setFormData] = useState({});
    const queryList = [...params];
    const storeKey = queryList[0][1];
    const mode = queryList[1][1];
    const companyCode = queryList[2][1];

    const containerStyle = useMemo(() => ({width: '100%', height: '100%', padding: 24}), []);
    const inputGroupWidth = 150;

    const defaultColDef = {
        flex: 1,
        resizable: true,
        sortable: true,
        filter: false,
        autoHeaderHeight: true
    };
    const [columnDefs] = useState([
        {field: 'seq', headerName: 'No', maxWidth: 80},
        {
            field: 'status', headerName: '진행상태', maxWidth: 90, cellRenderer: (e) => {
                return boardStatusFormatter(e.value)
            }
        },
        {field: 'boardTitle', headerName: "구분", maxWidth: 150},
        {
            field: 'postType', headerName: '분류', maxWidth: 200, valueFormatter: (e) => {
                return boardPostTypeFormatter(e.value)
            }
        },
        {field: 'storeNm', headerName: '상호명', minWidth: 250},
        {field: 'contents', headerName: '요청내용', minWidth: 200},
        {field: 'procContents', headerName: '처리내용'},
        {field: 'respAccountName', headerName: '담당자', maxWidth: 120},
        {field: 'accountUserName', headerName: '등록사용자', maxWidth: 120},
        {field: 'instDate', headerName: '등록일', maxWidth: 250}
    ])

    const [storeProductcolumnDefs] = useState([
        {field: 'storeKey', headerName: '매장코드', hide: true},
        {field: 'productCd', headerName: '상품코드', hide: true},
        {field: 'productNm', headerName: '상품명'},
        {field: 'lotNo', headerName: '시리얼번호'},
        {field: 'reason', headerName: '사유'},
        {field: 'status', headerName: '상태'}
    ])

    useEffect(() => {
        console.log(">>> storeKey :: ", storeKey);
        if (storeKey.length > 0) {
            search(storeKey);
            getStoreProductList();
        }

        if (mode === 'E') {
            getBoardList(storeKey);
        }
    }, []);

    const search = (storeKey) => {
        setLoading(true);
        api.getStore(storeKey).then((result) => {
            if (result.status === 200) {
                setData(result.data);
            }
        })
            .catch(error => {
                console.error("error", error);
                alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getStoreProductList = () => {
        const params = {
            companyCode: companyCode,
            storeKey: storeKey
        }
        api.getStoreHaveProductList(params).then(result => {
            const {data, status, statusText} = result;
            if (status === 200) {
                console.log("### storeProductList :: ", data);
                setStoreProductList(data);
            }
            else {
                window.alert(statusText);
            }
        })
            .catch(error => {
                window.alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const getBoardList = (storeKey) => {
        const params = {
            companyCode: companyCode,
            storeKey: storeKey
        }

        setLoading(true);
        api.getBoardListWhere(params).then((result) => {
            if (result.status === 200) {
                setBoardList(result.data);
            }
        })
            .catch(error => {
                console.error("error", error);
                alert(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const save = () => {
        if (confirm("등록하시겠습니까?")) {
            const params = {
                companyCode: companyCode,
                ...data
            }
            console.log("### params :: ", params);
            setLoading(true);

            try {
                api.putStore(params).then((result) => {
                    if (result.status === 200) {
                        // 성공
                        alert("정상적으로 처리되었습니다.");
                    }
                })
                    .finally(() => {
                        setLoading(false);
                    })
            } catch (e) {
                setLoading(false);
                console.error(e.message);

                alert(e.message);
            }
        }
    }

    const putBoard = () => {
        if(confirm("문의를 등록하시겠습니까?")) {
            const params = {
                companyCode: companyCode,
                boardId: 11,
                storeKey: storeKey,
                contents: formData.contents,
                instUser: "vanadmin"
            }
            console.log(">>> putBoard params :: ", params);

            setLoading(true);
            api.putBoard(params)
                .then((result) => {
                    if (result.status === 200) {
                        alert("처리가 완료되었습니다.");
                    }
                })
                .catch(error => {
                    console.error("error", error);
                    alert(error.message);
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const onRowSelected = useCallback((e) => {
        const params = "seq=" + e.data.seq + "&type=read";
        window.open("/#/popup/board/detail?" + params, "_detail", "width=800, height=600");
    }, []);

    return (
        <div className={"w-screen h-screen p-6"}>
            {loading ? <Loading/> : null}
            <div className="d-grid gap-1 d-md-flex justify-content-between">
                <p className={"text-xl font-bold"}>가맹점 등록관리</p>
                <button className={"bg-blue-950 rounded px-3 text-white text-sm font-bold"} onClick={save}>저장</button>
            </div>
            <div className={"flex flex-col"}>
                <div className={"flex flex-row bg-gray-500 gap-1 p-1"}>
                    <div className={"flex flex-col col-6 bg-white border-4 border-gray-200 gap-1"}>
                        <div className={"bg-gray-500 text-white text-center font-black py-1"}>기본정보</div>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>사업자번호</CInputGroupText>
                            <CFormInput type="numeric"
                                        id="inputBusiNo"
                                        maxLength={10}
                                        defaultValue={data.busiNo}
                                        disabled={mode === "E"}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                busiNo: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>대표자명</CInputGroupText>
                            <CFormInput type="text"
                                        id="inputOwnerNm"
                                        defaultValue={data.ownerNm}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                ownerNm: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>상호명</CInputGroupText>
                            <CFormInput type="numeric"
                                        defaultValue={data.storeName}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                storeName: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>법인번호</CInputGroupText>
                            <CFormInput type="numeric"
                                        defaultValue={data.corpNo}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                corpNo: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>주소</CInputGroupText>
                            <CFormInput type="text"
                                        id="inputAddr1"
                                        defaultValue={data.addr1}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                addr1: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>상세주소</CInputGroupText>
                            <CFormInput type="text"
                                        id="inputAddr2"
                                        defaultValue={data.addr2}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                addr2: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>연락처</CInputGroupText>
                            <CFormInput type="numeric"
                                        id="inputTelNo"
                                        defaultValue={data.telNo}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                telNo: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>휴대폰</CInputGroupText>
                            <CFormInput type="text"
                                        id="inputMobileNo"
                                        defaultValue={data.mobileNo}
                                        onChange={(event) => {
                                            setData((state) => ({
                                                ...state,
                                                mobileNo: event.target.value
                                            }));
                                        }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>업태</CInputGroupText>
                            <CFormSelect id={"businessType"}
                                         value={data.businessType}
                                         options={businessOptions}
                                         onChange={(e) => {
                                             setData((state) => ({
                                                 ...state,
                                                 businessType: e.target.value
                                             }))
                                         }}
                            />
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>종목</CInputGroupText>
                            <CFormSelect id={"eventType"}
                                         value={data.eventType}
                                         options={eventOptions}
                                         onChange={(e) => {
                                             setData((state) => ({
                                                 ...state,
                                                 eventType: e.target.value
                                             }))
                                         }}
                            />
                        </CInputGroup>
                    </div>
                    <div className={"flex flex-col col-6 bg-white border-4 border-gray-200 gap-1"}>
                        <div className={"flex-1 bg-gray-500 text-white text-center font-black py-1"}>상세정보</div>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>대표자메일</CInputGroupText>
                            <CFormInput type="text"
                                        value={data.ownerEmail}
                                        onChange={(e) => {
                                            setData((state) => ({
                                                ...state,
                                                ownerEmail: e.target.value
                                            }));
                                        }}/>
                            <CInputGroupText>@</CInputGroupText>
                            <CFormInput type="text" value={emailHost} defaultValue={emailHost}
                                        onChange={(e) => {
                                            setEmailHost(e.target.value)
                                        }}
                                        disabled={selectEmailHost.length > 0}/>
                            <CFormSelect onChange={(e) => {
                                setSelectEmailHost(e.target.value);
                                setEmailHost(e.target.value);
                            }}>
                                <option value={""}>직접입력</option>
                                <option value={"gmail.com"}>gmail.com</option>
                                <option value={"daum.net"}>daum.net</option>
                                <option value={"kakao.com"}>kakao.com</option>
                                <option value={"nate.com"}>nate.com</option>
                                <option value={"naver.com"}>naver.com</option>
                            </CFormSelect>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>부가세용 팩스</CInputGroupText>
                            <CFormInput type="text" defaultValue={data.vatFaxNo} onChange={(e) => {
                                setData((state) => ({
                                    ...state,
                                    vatFaxNo: e.target.value
                                }));
                            }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>부가세용 메일</CInputGroupText>
                            <CFormInput type="text" defaultValue={data.vatEmail} onChange={(e) => {
                                setData((state) => ({
                                    ...state,
                                    vatEmail: e.target.value
                                }));
                            }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>A/S담당자</CInputGroupText>
                            <CFormSelect>
                                <option>선택...</option>
                            </CFormSelect>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>사용용지</CInputGroupText>
                            <CInputGroupText style={{background: "white"}}>
                                <CFormCheck inline id="inlineCheckbox1" value="50" label="50"/>
                                <CFormCheck inline id="inlineCheckbox2" value="60" label="60"/>
                                <CFormCheck inline id="inlineCheckbox2" value="70" label="70"/>
                                <CFormCheck inline id="inlineCheckbox2" value="80" label="80"/>
                                <CFormCheck inline id="inlineCheckbox2" value="m" label="무선"/>
                            </CInputGroupText>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>용지출고단위(BOX)</CInputGroupText>
                            <CFormInput type="text" defaultValue={""} onChange={(e) => {

                            }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>용지사용기간(월)</CInputGroupText>
                            <CFormInput type="text" defaultValue={""} onChange={(e) => {

                            }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>용지배송대상</CInputGroupText>
                            <CInputGroupText style={{background: "white"}}>
                                <CFormCheck inline id="inlineCheckbox1"/>
                            </CInputGroupText>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>집중관리</CInputGroupText>
                            <CInputGroupText style={{background: "white"}}>
                                <CFormCheck inline id="inlineCheckbox1"/>
                            </CInputGroupText>
                            <CFormInput type="text" defaultValue={""} onChange={(e) => {

                            }}/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            &nbsp;
                        </CInputGroup>
                    </div>
                </div>
                <div className={"flex flex-row bg-gray-500 gap-1 pl-1 pr-1 pb-1"}>
                    <div className={"flex flex-col col-6 bg-white border-4 border-gray-200 gap-1"}>
                        <div className={"bg-gray-500 text-white text-center font-black py-1"}>기타정보</div>
                            <CInputGroup size={"sm"}>
                                <CInputGroupText style={{width: inputGroupWidth}}>메모</CInputGroupText>
                                <CFormTextarea
                                    id="inputMemo"
                                    defaultValue={data.memo}
                                    onChange={(e) => {
                                        setData((state) => ({
                                            ...state,
                                            memo: e.target.value
                                        }))}}
                                    rows="3"/>
                            </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>계약정보</CInputGroupText>
                            <CFormTextarea
                                id="inputContract"
                                defaultValue={data.contractInfo}
                                onChange={(e) => {
                                    setData((state) => ({
                                        ...state,
                                        contractInfo: e.target.value
                                    }))}}
                                rows="3"/>
                        </CInputGroup>
                        <CInputGroup size={"sm"}>
                            <CInputGroupText style={{width: inputGroupWidth}}>기타</CInputGroupText>
                            <CFormTextarea
                                id="inputEtc"
                                defaultValue={data.etcInfo}
                                onChange={(e) => {
                                    setData((state) => ({
                                        ...state,
                                        etcInfo: e.target.value
                                    }))}}
                                rows="3"/>
                        </CInputGroup>
                    </div>
                    <div className={"flex flex-col col-6 bg-white border-4 border-gray-200 gap-1"}>
                        <div className={"bg-gray-500 text-white text-center font-black py-1"}>장비정보</div>
                        <div className="d-grid gap-2 ag-theme-alpine" style={{width: '100%', height: 230}}>
                            <AgGridReact
                                headerHeight={32}
                                rowData={storeProductList}
                                defaultColDef={defaultColDef}
                                columnDefs={storeProductcolumnDefs}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"d-grid gap-2 my-3"}>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: inputGroupWidth}}>문의내용</CInputGroupText>
                        <CFormTextarea
                            value={formData.contents}
                            id="inputMemo"
                            rows="4"
                            onChange={(event) => {
                                setFormData((state) => ({
                                    ...state,
                                    contents: event.target.value
                                }));
                            }}/>
                        <button className={"bg-blue-950 rounded-r-xl text-white font-black"} style={{width: 100}} type={"button"}
                                 onClick={() => {
                                    putBoard();
                                 }}>등록</button>
                    </CInputGroup>
                </CCol>
            </div>
            <div className="flex flex-row justify-content-between py-2">
                <h5 className={"text-xl font-bold"}>매장 문의 이력</h5>
                <button className={"bg-blue-950 rounded px-3 py-1 text-white text-sm font-bold"} onClick={() => search(storeKey)}>조회</button>
            </div>
            <div className="d-grid gap-2 ag-theme-alpine" style={{width: '100%', height: 600}}>
                <AgGridReact
                    rowSelection={'single'}
                    rowHeight={32}
                    rowData={boardList}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    onRowDoubleClicked={onRowSelected}>
                </AgGridReact>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const {account, store} = state;
    return {
        account, store
    };
};

export default connect(mapStateToProps, null)(PopupStoreInfo);