export const businessOptions = [
    {value: "", label: "선택"},
    {value: "0", label: "건축"},
    {value: "1", label: "고압가스"},
    {value: "2", label: "교육서비스업"},
    {value: "3", label: "냉난방기"},
    {value: "4", label: "도소매"},
    {value: "5", label: "배관"},
    {value: "6", label: "보건,위생"},
    {value: "7", label: "부동산업"},
    {value: "8", label: "서비스"},
    {value: "9", label: "세탁"},
    {value: "10", label: "소매업"},
    {value: "11", label: "숙박업"},
    {value: "12", label: "스포츠시설"},
    {value: "13", label: "연료"},
    {value: "14", label: "운수업"},
    {value: "15", label: "유흥"},
    {value: "16", label: "음식료품"},
    {value: "17", label: "음식점업"},
    {value: "18", label: "인테리어"},
    {value: "19", label: "자동차"},
    {value: "20", label: "전기"},
    {value: "21", label: "전자상거래"},
    {value: "22", label: "철물"},
    {value: "23", label: "컴퓨터"},
    {value: "24", label: "핸드폰"},
    {value: "25", label: "헤어/뷰티"},
    {value: "26", label: "기타"}
];