import * as api from "../api";

export const getStoreListWhere = async (params) => {
	try {
		const {data: result} = await api.getStoreWhere(params);
		if (result !== '') {
			return result;
		}
	} catch (e) {
		console.error(e.message);
		return [];
	}
}
