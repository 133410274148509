import React from 'react'
import {CSpinner} from '@coreui/react'

const Loading = (props) => {
	return (
		<div className={"h-screen z-999"} style={{
			position: 'absolute',
			background: '#00000088',
			zIndex: 999,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			top: 0,
			left: 0,
			width: '100vw'
		}}>
			<svg width="80" height="80" version="1.1" viewBox="0 0 500 500">
				<g>
					<g>
						<path style={{fill: 'white'}}
							d="M236.6,118.7c-56-41.6-135.1-30-176.7,26c-41.6,56-30,135.1,26,176.7s135.1,30,176.7-26    C304.2,239.4,292.6,160.3,236.6,118.7z M118,278.2c-32.1-23.9-38.8-69.3-14.9-101.5s69.3-38.8,101.5-14.9s38.8,69.3,14.9,101.5    C195.5,295.5,150.1,302.1,118,278.2z"/>
						<g>
							<path style={{fill: 'white'}}
								d="M195,174.7c-25-18.6-60.4-13.4-79.1,11.6c-18.6,25-13.4,60.4,11.6,79.1s60.4,13.4,79.1-11.6     C225.2,228.7,220,193.3,195,174.7z M135.8,254.3c-18.9-14.1-22.8-40.8-8.8-59.7s40.8-22.8,59.7-8.8c18.9,14.1,22.8,40.8,8.8,59.7     S154.7,268.4,135.8,254.3z"/>
						</g>
						<circle cx="161.2" cy="220" id="XMLID_22_" r="27.5"/>
						<g>
							<path style={{fill: 'white'}}
								d="M241,150.7L207.8,126l28.5-33.6c2.9-3.4,7.8-4,11.2-1.4l15.5,11.5c3.4,2.5,4.2,7.5,1.8,11.2L241,150.7z"
								id="XMLID_21_"/>
							<path style={{fill: 'white'}}
								d="M266.7,227.4l-6-40.9l43.9-3.6c4.4-0.4,8.4,2.7,9,6.9l2.8,19.1c0.6,4.2-2.3,8.3-6.6,9.2L266.7,227.4z"
								id="XMLID_20_"/>
							<path style={{fill: 'white'}}
								d="M230.6,299.8l24.6-33.1l33.6,28.5c3.4,2.9,4,7.8,1.4,11.2l-11.5,15.5c-2.5,3.4-7.5,4.2-11.2,1.8     L230.6,299.8z"
								id="XMLID_19_"/>
							<path style={{fill: 'white'}}
								d="M153.9,325.5l40.9-6l3.6,43.9c0.4,4.4-2.7,8.4-6.9,9l-19.1,2.8c-4.2,0.6-8.3-2.3-9.2-6.6L153.9,325.5z"
								id="XMLID_18_"/>
							<path style={{fill: 'white'}}
								d="M81.5,289.4l33.1,24.6l-28.5,33.6c-2.9,3.4-7.8,4-11.2,1.4l-15.5-11.5c-3.4-2.5-4.2-7.5-1.8-11.2     L81.5,289.4z"
								id="XMLID_17_"/>
							<path style={{fill: 'white'}}
								d="M55.8,212.7l6,40.9l-43.9,3.6c-4.4,0.4-8.4-2.7-9-6.9l-2.8-19.1c-0.6-4.2,2.3-8.3,6.6-9.2L55.8,212.7z"
								id="XMLID_16_"/>
							<path style={{fill: 'white'}}
								d="M91.9,140.3l-24.6,33.1l-33.6-28.5c-3.4-2.9-4-7.8-1.4-11.2l11.5-15.5c2.5-3.4,7.5-4.2,11.2-1.8     L91.9,140.3z"
								id="XMLID_15_"/>
							<path style={{fill: 'white'}}
								d="M168.6,114.6l-40.9,6l-3.6-43.9c-0.4-4.4,2.7-8.4,6.9-9l19.1-2.8c4.2-0.6,8.3,2.3,9.2,6.6L168.6,114.6z"
								id="XMLID_14_"/>
						</g>
						<animateTransform attributeName="transform"
										  attributeType="XML"
										  type="rotate"
										  from="0 161.2 220"
										  to="360 161.2 220"
										  dur="1.5s"
										  repeatCount="indefinite"/>
					</g>
				</g>
			</svg>
			<div className={"rounded bg-white px-2 py-1"}>잠시만 기다려주세요</div>
		</div>
	)
}

export default Loading