import React from "react";

const InputTextGroup = (props) => {
    const handleInput = (e) => {
        props.setValue(e)
    }

    return (
        <div className={"flex flex-row p-2 gap-2 items-center"}>
            <span className={"w-20 text-sm font-bold text-nowrap"}>{props.title}</span>
            <textarea className={"w-full p-2 text-sm border border-gray-500 rounded"}
                      id={props.id}
                      name={props.name}
                      value={props.value}
                      onChange={handleInput}
                      disabled={props.disabled}
            />
        </div>
    )
}

export default InputTextGroup;