export const eventOptions = [
    { value: "", label: "선택" },
    { value: "0", label: "반찬" },
    { value: "1", label: "일식/횟집" },
    { value: "2", label: "제과점업" },
    { value: "3", label: "중식/양식" },
    { value: "4", label: "치킨,피자" },
    { value: "5", label: "카페" },
    { value: "6", label: "커피전문점" },
    { value: "7", label: "한식/분식" },
    { value: "8", label: "휴게/간이음식" },
    { value: "9", label: "기타" },
];