import {connect} from "react-redux";
import React, {useCallback, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {Loading} from "../../../components/common";

const SupplierSearch = (props) => {
	console.log("### props :: ", props.data);

	const [columnDefs] = useState([
		{field: 'type', headerName: "구분", maxWidth: 100},
		{field: 'supplierCd', headerName: '코드', maxWidth: 120},
		{field: 'supplierNm', headerName: '명', flex: 1}
	])

	const onRowSelected = useCallback((event) => {
		props.callback(event.node.data);
	}, []);

	return (
		<>
			{props.data.length > 0 ?
				<div className="ag-theme-alpine" style={{width: '100%', height: '100%'}}>
					<AgGridReact
						domLayout={"autoHeight"}
						rowSelection={'single'}
						rowData={props.data}
						columnDefs={columnDefs}
						onRowDoubleClicked={onRowSelected}>
					</AgGridReact>
				</div>
				: <div>데이터가 없습니다.</div>}
		</>
	)
}

const mapStateToProps = (state) =>
{
	const {account, store} = state;
	return {
		account, store
	};
}
;

export default connect(mapStateToProps, null)(SupplierSearch);
