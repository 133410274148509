const INIT_ACCOUNT = '@@INIT_ACCOUNT';
const SET_ACCOUNT = '@@SET_ACCOUNT';

const initialState = {};

function initAccount() {
  return {
    type: INIT_ACCOUNT,
  };
}

function applyInitAccount() {
  return {};
}

function setAccount(user) {
  return {
    type: SET_ACCOUNT,
    user,
  };
}

function applySetAccount(state, action) {
  const {user} = action;
  return {
    ...state,
    user,
  };
}

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
    case INIT_ACCOUNT:
      return applyInitAccount();
    case SET_ACCOUNT:
      return applySetAccount(state, action);
  }
}

const actionCreator = {
  initAccount,
  setAccount,
};

export {actionCreator as accountActions};

export default reducer;
