import React from "react";

const InputTextGroup = (props) => {
    const handleInput = (e) => {
        props.setValue(e)
    }

    return (
        <div className={"flex flex-row gap-2 items-center"}>
            <span className={"w-20 text-sm font-bold text-nowrap"}>{props.title}</span>
            <input type={"text"} className={"w-36 h-6 p-2 text-sm border border-gray-500 rounded"}
                   id={props.id}
                   name={props.name}
                   value={props.value}
                   onKeyPress={props.onKeyPress}
                   onChange={handleInput}
                   disabled={props.disabled}
            />
        </div>
    )
}

export default InputTextGroup;