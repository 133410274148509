import numeral from "numeral";
import dayjs from "dayjs";

export function currencyFormatter(params) {
	return numeral(params.value).format("0,0");
}

export function timeFormatter(params, dataFormat, resultFormat) {
	return dayjs(params.value, dataFormat).format(resultFormat);
}

export function boardStatusFormatter(value) {
	let textValue = "";
	let bgColor = "";
	switch (value) {
		case "0":
			bgColor = 'bg-gray-400'
			textValue = "접수"
			break;
		case "1":
			bgColor = 'bg-blue-400'
			textValue = "처리중"
			break;
		case "9":
			bgColor = 'bg-green-400'
			textValue =  "완료"
			break;
		case "C":
			bgColor = 'bg-gray-400'
			textValue =  "취소"
			break;
		case "H":
			bgColor = 'bg-red-400'
			textValue =  "보류"
			break;
	}
	return <div className={`mt-1 flex-1 rounded border border-blue-950 text-center text-sm text-white ${bgColor}`}>{textValue}</div>;
}

export function boardPostTypeFormatter(value) {
	switch (value) {
		case "as-pos-01":
			return "전원이 안들어와요";
		case "as-pos-02":
			return "용지가 안올라와요";
		case "as-pos-03":
			return "카드가 인식안돼요";
		case "as-pos-04":
			return "카드승인이 안돼요";
		case "as-pos-05":
			return "인터넷이 안돼요";
		case "as-pos-06":
			return "카드를 꽃아도 인식을 안해요";
		case "as-pos-07":
			return "사인패드가 안돼요";
		case "as-pos-08":
			return "영수증이 안올라와요";
		case "as-pos-09":
			return "주방프린터기가 안올라와요";
		case "as-pos-10":
			return "프로그램이 실행이 안되요";
		case "as-terminal-01":
			return "전원이 안들어와요";
		case "as-terminal-02":
			return "용지가 안올라와요";
		case "as-terminal-03":
			return "카드가 인식안돼요";
		case "as-terminal-04":
			return "카드승인이 안돼요";
		case "as-terminal-05":
			return "인터넷이 안돼요";
		case "invoice-1-4-01":
			return "세금계산서(1/4분기)"
		case "invoice-2-4-01":
			return "세금계산서(2/4분기)"
		case "invoice-3-4-01":
			return "세금계산서(3/4분기)"
		case "invoice-4-4-01":
			return "세금계산서(4/4분기)"
		case "invoice-first-half-01":
			return "세금계산서(상반기)"
		case "invoice-last-half-01":
			return "세금계산서(하반기)"
		case "invoice-all-01":
			return "세금계산서(1년)"
		case "terminal-chit-01":
			return "단말기용지 (소)1box";
		case "terminal-chit-02":
			return "단말기용지 (중)1box";
		case "terminal-chit-03":
			return "단말기용지 (대)1box";
		default:
			return "미분류";
	}
}