import {connect} from "react-redux";
import React, {useCallback, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {Loading} from "../../../components/common";

const StoreSearch = (props) => {
	const [columnDefs] = useState([
		{field: 'storeKey', headerName: '코드', hide: true},
		{field: 'storeName', headerName: '상호(창고)명', flex: 1},
		{field: 'busiNo', headerName: '사업자번호', width: 130},
		{field: 'ownerNm', headerName: '대표자', width: 100}
	])

	const onRowSelected = useCallback((event) => {
		props.callback(event.node.data);
	}, []);

	return (
		<>
			{props.data.length > 0 ?
				<div className="ag-theme-alpine" style={{width: '100%', height: '100%'}}>
					<AgGridReact
						domLayout={"autoHeight"}
						rowSelection={'single'}
						rowData={props.data}
						columnDefs={columnDefs}
						onRowDoubleClicked={onRowSelected}>
					</AgGridReact>
				</div>
				: <div>데이터가 없습니다.</div>}
		</>
	)
}

const mapStateToProps = (state) =>
{
	const {account, store} = state;
	return {
		account, store
	};
}
;

export default connect(mapStateToProps, null)(StoreSearch);
