import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

import accountReducer from '../redux-store/actions/account';
import footerReducer from '../redux-store/actions/footer';

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  account: accountReducer,
  state: changeState,
  footer: footerReducer
})

const store = createStore(rootReducer)
export default store
