import React from "react";
import {SearchOutlined} from "@ant-design/icons";

const InputTextGroup = (props) => {
    const handleInput = (e) => {
        props.setValue(e)
    }

    const onSearch = () => {
        props.search(props.value);
    }

    return (
        <div className={"flex flex-row p-2 gap-2 items-center"}>
            <span className={"w-20 text-sm font-bold text-nowrap"}>{props.title}</span>
            <div className={"flex flex-row"}>
                <input type={"text"} className={"w-36 h-6 p-2 text-sm border border-gray-500 rounded-l"}
                       id={props.id}
                       name={props.name}
                       value={props.value}
                       onChange={handleInput}
                       disabled={props.disabled}/>
                <button className={"bg-blue-800 rounded-r text-white px-1 items-center text-center"} onClick={onSearch}>
                    <span className={"text-sm px-1"}>조회</span>
                </button>
            </div>
        </div>
    )
}

export default InputTextGroup;