import {useState} from "react";

const ComboBox = (props) => {
    const {options} = props;
    const [value, setValue] = useState(props.value);

    const handleChange = (e) => {
        props.onEventChange(props.rowIndex, e.target.value);
    }

    return (
        <select value={value}
                className={"border h-6"}
                onChange={handleChange}
        >
            {options.map((option, index) => (
                <option key={index}
                        value={option.subCode}>
                    {option.codeNm}
                </option>
            ))}
        </select>
    )
}

export default ComboBox;