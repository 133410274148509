import {useSearchParams} from "react-router-dom";
import {Loading} from "../../components/common";
import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormSelect,
    CFormTextarea,
    CInputGroup,
    CInputGroupText, CModal, CModalBody, CModalFooter, CModalHeader,
    CRow
} from "@coreui/react";
import {SearchOutlined} from "@ant-design/icons";
import StoreSearch from "../pages/board/storeSearch";
import React, {useCallback, useEffect, useState} from "react";
import {getBoardDetail, getBoardReply, getStore, selectWorkManagerList} from "../../api/api";
import * as api from "../../api/api";
import {getStoreListWhere} from "../../api/method/store";
import LayoutUtil from "../../utils/LayoutUtil";
import * as storeMethod from "../../api/method/store";

const PopupBoardDetail = () => {
    const [params] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        visible: false,
        message: ""
    });
    const [storeSearchModal, setStoreSearchModal] = useState({
        visible: false,
        data: []
    });
    const [employees, setEmployees] = useState([]);
    const [formData, setFormData] = useState({});
    const [reply, setReply] = useState("");
    const [replyData, setReplyData] = useState([]);

    // const queryList = [...params];
    const boardId = params.get("seq");
    const mode = params.get("mode");
    const companyCode = params.get("companyCode");
    const accountId = params.get("accountId");

    useEffect(() => {
        console.log("### boardId :: ", boardId);
        if (boardId) {
            fncGetBoardDetail(boardId);
        }
        fncGetEmployee();
    }, [])

    const getStoreWhere = (value) => {
        if (value.length === 0) {
            alert("검색 정보를 입력해주세요.");
            return;
        }

        const params = {
            companyCode: companyCode,
            storeSyn: value
        }
        storeMethod.getStoreListWhere(params).then((result) => {
            setLoading(false);
            setStoreSearchModal({
                visible: true,
                data: result
            })
        })
    }

    const fncGetEmployee = () => {
        setLoading(true);
        const params = {
            companyCode: "VAN",
        };

        selectWorkManagerList(params).then((result) => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setEmployees([{managerDiv: 'EMP', id: "", managerNm: "미지정"}]);
                setEmployees((state) => state.concat(data));
            }
            else {
                Alert.alert("서버에 문제가 있습니다. 잠시 후 다시 시도해 주세요.\n" + statusText)
            }
        })
            .catch((e) => {
                Alert.alert("서버에 문제가 있습니다. 잠시 후 다시 시도해 주세요.\n" + e.message)
            })
            .finally(() => {
                setLoading(false);
            })

    }

    const putBoard = async (data) => {
        const params = {
            ...data,
            companyCode: 'VAN',
            boardId: boardId ? boardId : '11',
            instUser: data.instUser ? data.instUser : accountId,
            updUser: accountId,
        }

        setLoading(true);
        try {
            const {data: result} = await api.putBoard(params);
            if (result !== '') {
                // 성공
                setModal({
                    visible: true,
                    message: "처리가 완료되었습니다."
                })
            } else {

            }
        } catch (e) {
            console.error(e.message);
            setModal({
                visible: true,
                message: e.message
            })
        } finally {
            setLoading(false);
        }
    }

    const fncGetBoardDetail = (seq) => {
        setLoading(true);
        getBoardDetail(seq).then((result) => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setFormData(data);
                fncGetStore(data.storeKey);

                fncGetBoardReply(seq);
            }
            else {
                Alert.alert("서버에 문제가 있습니다. 잠시 후 다시 시도해 주세요.\n" + statusText)
            }
        })
            .catch((e) => {
                Alert.alert("서버에 문제가 있습니다. 잠시 후 다시 시도해 주세요.\n" + e.message)
                setLoading(false);
            })
    }

    const fncGetBoardReply = async (seq) => {
        try {
            const {data: result} = await api.getBoardReply(seq);
            if (result !== '') {
                // 성공
                setReplyData(result);
            }
        } catch (e) {
            console.error(e.message);
        } finally {
            setLoading(false);
        }
    }

    const fncGetStore = (storeKey) => {
        setLoading(true);
        getStore(storeKey).then((result) => {
            const {status, statusText, data} = result;
            if (status === 200) {
                setFormData((state) => ({
                    ...state,
                    storeName: data.storeName,
                    busiNo: data.busiNo,
                    telNo: data.telNo,
                    email: data.email,
                    addr1: data.addr1,
                    addr2: data.addr2
                }))
            }
            else {
                Alert.alert("서버에 문제가 있습니다. 잠시 후 다시 시도해 주세요.\n" + statusText)
            }
        })
            .catch((e) => {

            })
            .finally(() => {
                setLoading(false);
            })
    }

    const putBoardReply = () => {
        const body = {
            boardId: boardId,
            reply: reply,
            instUser: accountId,
            updUser: accountId
        }

        setLoading(true);
        api.putBoardReply(body)
            .then(response => {
                if (response.status === 200) {
                    fncGetBoardReply(boardId);

                    setReply("");
                }
            })
            .catch(error => {
                console.error("error", error);
                alert("등록에 실패하였습니다.\n" + error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const storeSelectCallback = (e) => {
        setStoreSearchModal({
            visible: false, data: []
        })
        setFormData((state) => ({
            ...state,
            storeKey: e.storeKey,
            storeName: e.storeName,
            busiNo: e.busiNo,
            telNo: e.telNo,
            email: e.email,
            addr1: e.addr1,
            addr2: e.addr2
        }))
    }

    const fncOnStore = (storeKey, companyCode) => {
        const params = "storeKey=" + storeKey + "&mode=E" + "&companyCode=" + companyCode;
        window.open('/#/popup/store/info?' + params, "_pay", "width=1024, height=768 fullscreen");
    }

    return (
        <div className={"flex flex-col p-2 gap-1"}>
            {loading ? <Loading/> : null}
            <div className="flex justify-content-between">
                <button className={"bg-blue-950 px-3 py-1 rounded text-white"} onClick={() => fncOnStore(formData.storeKey, formData.companyCode)}>가맹점정보</button>
                <button className={"bg-red-500 px-3 py-1 rounded text-white"} onClick={() => putBoard(formData)}>등록</button>
            </div>
            <div className="flex flex-col gap-1">
                <CInputGroup>
                    <CInputGroupText style={{width: 100}}>진행상태</CInputGroupText>
                    <CFormSelect id="state"
                                 value={formData.status}
                                 onChange={(e) => {
                                     setFormData((state) => ({
                                         ...state,
                                         status: e.target.value
                                     }))
                                 }}>
                        <option value={"0"}>접수</option>
                        <option value={"1"}>처리중</option>
                        <option value={"9"}>완료</option>
                        <option value={"C"}>취소</option>
                        <option value={"H"}>보류</option>
                    </CFormSelect>
                </CInputGroup>
                <CInputGroup>
                    <CInputGroupText style={{width: 100}}>가맹점</CInputGroupText>
                    <CFormInput id="storeNm"
                                value={formData.storeName}
                                onChange={(e) => {
                                    setFormData((state) => ({
                                        ...state,
                                        storeName: e.target.value
                                    }))
                                }}
                                disabled={mode === "read"}/>
                    <CButton className={"bg-blue-800"}  type={"button"} disabled={mode === "read"}
                             onClick={() => {
                                 const storeSyn = formData.storeName
                                 getStoreWhere(storeSyn)
                             }}><SearchOutlined/></CButton>
                    <CFormInput id="busiNo"
                                value={formData.busiNo}
                                disabled={true}/>
                </CInputGroup>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>등록자</CInputGroupText>
                        <CFormInput id="userName"
                                    disabled={true}/>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>연락처</CInputGroupText>
                        <CFormInput id="etc01"
                                    value={formData.telNo}
                                    onChange={(e) => {
                                        setFormData((state) => ({
                                            ...state,
                                            telNo: e.target.value
                                        }))
                                    }}
                                    disabled={mode === "read"}/>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>주소</CInputGroupText>
                        <CFormInput id="addr1"
                                    value={formData.addr1}
                                    onChange={(e) => {
                                        setFormData((state) => ({
                                            ...state,
                                            addr1: e.target.value
                                        }))
                                    }}
                                    disabled={mode === "read"}/>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>상세주소</CInputGroupText>
                        <CFormInput id="addr2"
                                    value={formData.addr2}
                                    onChange={(e) => {
                                        setFormData((state) => ({
                                            ...state,
                                            addr2: e.target.value
                                        }))
                                    }}
                                    disabled={mode === "read"}/>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>Email</CInputGroupText>
                        <CFormInput id="etc01"
                                    value={formData.email}
                                    onChange={(e) => {
                                        setFormData((state) => ({
                                            ...state,
                                            email: e.target.value
                                        }))
                                    }}
                                    disabled={mode === "read"}/>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>담당자</CInputGroupText>
                        <CFormSelect id="state"
                                     value={formData.respAccountId}
                                     onChange={(e) => {
                                         setFormData((state) => ({
                                             ...state,
                                             respAccountId: e.target.value
                                         }))
                                     }}>
                            {employees.map((emp, index) => {
                                return (
                                    <option key={index}
                                            value={emp.managerDiv === 'EMP' ? emp.id : "@@T" + emp.key}>{emp.managerNm}</option>
                                )
                            })}
                        </CFormSelect>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>문의내용</CInputGroupText>
                        <CFormTextarea
                            value={formData.contents}
                            id="inputMemo"
                            rows="4"
                            onChange={(event) => {
                                setFormData((state) => ({
                                    ...state,
                                    contents: event.target.value
                                }));
                            }}/>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>처리내용</CInputGroupText>
                        <CFormTextarea
                            value={formData.procContents}
                            placeholder={"내용을 입력해주세요."}
                            id="inputMemo"
                            rows="4"
                            onChange={(event) => {
                                setFormData((state) => ({
                                    ...state,
                                    procContents: event.target.value
                                }));
                            }}/>
                    </CInputGroup>
                </CCol>
                <CCol xs={12}>
                    <CInputGroup>
                        <CInputGroupText style={{width: 100}}>댓글달기</CInputGroupText>
                        <CFormTextarea
                            value={reply}
                            placeholder={"내용을 입력해주세요."}
                            rows="3"
                            onChange={(event) => {
                                setReply(event.target.value);
                            }}/>
                        <button className={"w-20 bg-blue-950 rounded-r-md text-white font-bold text-md"}
                                 onClick={putBoardReply}>등록</button>
                    </CInputGroup>
                </CCol>

                {replyData.map((reply, index) => {
                    return (
                        <CRow>
                            <CCol md={1}>
                                <span style={{fontSize: 20, fontWeight: "bold"}}>ㄴ</span>
                            </CCol>
                            <CCol md={10} style={{
                                flex: 1,
                                padding: 10,
                                backgroundColor: '#efefef',
                                borderWidth: 1,
                                borderColor: '#dedede',
                                borderRadius: 10
                            }}>
                                <div style={{flexDirection: 'row'}}>
                                    <span style={{fontSize: 17, fontWeight: 'bold'}}>{reply.accountUserName}</span>
                                    <span style={{fontSize: 15}}> ({reply.instDate})</span>
                                </div>
                                <span style={{fontSize: 17, marginTop: 15}}>{reply.reply}</span>
                            </CCol>
                            <div style={{height: 10}}/>
                        </CRow>
                    )
                })}
            </div>
            <CModal alignment="center"
                    visible={modal.visible}
                    onClose={() => {
                        setModal({
                            visible: false, message: ""
                        })
                        window.close();
                    }}>
                <CModalBody>
                    {modal.message}
                </CModalBody>
                <CModalFooter>
                    <CButton className={"bg-blue-800"} color="primary" onClick={() => {
                        setModal({
                            visible: false,
                            message: ""
                        });
                    }}>확인</CButton>
                </CModalFooter>
            </CModal>
            <CModal alignment="center"
                    visible={storeSearchModal.visible}
                    onClose={() => setStoreSearchModal({
                        visible: false, data: []
                    })}>
                <CModalHeader/>
                <CModalBody>
                    <StoreSearch data={storeSearchModal.data} callback={storeSelectCallback}/>
                </CModalBody>
            </CModal>
        </div>
    )
}

export default PopupBoardDetail;
